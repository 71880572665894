























import { Component, Vue, Watch } from 'vue-property-decorator'
import dayjs from '@/libs/dayjs'
import BodyTempChart from '@/components/organisms/Group/BodyTempChart.vue'
import BodyTempTranditionChart from '@/components/organisms/Group/BodyTempTranditionChart.vue'
import SymptomDistributionsChart from '@/components/organisms/Group/SymptomDistributionsChart.vue'
import ConditionTable from '@/components/organisms/Group/ConditionTable.vue'
import CurrentDate from '@/components/molecules/CurrentDate.vue'
import Csv from '@/components/organisms/Group/Csv.vue'
import menuStore from '@/store/Menu'
import groupBodyTempDistributionsStore from '@/store/GroupBodyTempDistributions'
import bodyTempTranditionsStore from '@/store/BodyTempTranditions'
import conditionsStore from '@/store/Conditions'
import symptomDistributionsStore from '@/store/SymptomDistributions'
import _ from 'lodash'
import symptomsStore from '@/store/Symptoms'

@Component({
  components: {
    BodyTempChart,
    BodyTempTranditionChart,
    SymptomDistributionsChart,
    ConditionTable,
    CurrentDate,
    Csv,
  },
})
export default class extends Vue {
  @Watch('$route')
  async onChangeGroupId(): Promise<void> {
    try {
      await this.fetch()
      this.redirectIfCantFetchGroup()
    } catch (error) {
      console.error(error)
      this.$router.replace({ name: 'Home' })
    }
  }

  async mounted(): Promise<void> {
    try {
      await this.fetch()
      this.redirectIfCantFetchGroup()
    } catch (error) {
      console.error(error)
      this.$router.replace({ name: 'Home' })
    }
  }

  private async fetch() {
    // 初期化
    menuStore.setActiveFlg({ home: false, person: false, group: true })
    menuStore.setPersons([])
    groupBodyTempDistributionsStore.setGroupBodyTempDistributions([])
    bodyTempTranditionsStore.setBodyTempTranditions([])
    conditionsStore.setConditions([])
    symptomsStore.setSymptoms([])

    const startDate = dayjs().subtract(1, 'months')
    const endDate = dayjs()
    const groupId = this.$route.params.id

    await Promise.all([
      menuStore.fetchGroups().then(() => {
        menuStore.activateGroup(this.$route.params.id)
      }),
      menuStore.fetchUsers({ groupId: this.$route.params.id }),
      groupBodyTempDistributionsStore.fetch({ date: endDate, groupId }),
      bodyTempTranditionsStore.fetch({ startDate, endDate, options: { groupId } }),
      symptomDistributionsStore.fetchByGroup({ startDate: dayjs().subtract(13, 'd'), endDate: dayjs(), groupId }),
      conditionsStore.fetchByGroup({ date: endDate, groupId }),
      symptomsStore.fetch(),
    ])
  }

  redirectIfCantFetchGroup(): void {
    const group = _.find(menuStore.groups, { active: true })
    if (!group) this.$router.replace({ name: 'Home' })
  }
}
