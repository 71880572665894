import store from '@/store'
import { Module, VuexModule, Action, Mutation, getModule } from 'vuex-module-decorators'
import dayjs, { Dayjs } from '@/libs/dayjs'
import axios from 'axios'
import _ from 'lodash'

export type TBodyTempTrandition = {
  id: string
  firstName: string
  lastName: string
  conditions: TCondition[]
}

export type TCondition = {
  body_temp: number
  with_contact_notification: boolean
  message: string
  date: Dayjs
  symptoms: TSymptoms[]
  medical_interview: TMedicalInterview
}

export type TSymptoms = {
  name: string
  onset: boolean
}

export type TMedicalInterview = {
  id: string
  medical_interview: {
    fever: {
      flag: boolean[]
      temperature: number
      averageTemperature: string
    }
  }
}

/** ユーザー体温推移 */
export type TUserBodyTempTrandition = { history: THistoryInfo[]; averageTemperature: number }

/** 体温体調履歴 */
export type THistoryInfo = {
  /** YYYY/MM/DD hh:mm:SS 形式 */
  dateTime: string | Date
  /** 体温 */
  bodyTemp: number
  /** 症状ありかどうか true:あり、false:なし */
  withSymptoms: boolean
  /** メッセージ */
  message: string
  /** 問診票ID */
  medicalInterviewId: string
  /** 接触確認アプリ通知 */
  withContactNotification: boolean
}

@Module({ dynamic: true, namespaced: true, name: 'bodyTempTrandition', store })
class Mod extends VuexModule {
  bodyTempTranditions: TBodyTempTrandition[] = []

  @Mutation
  setBodyTempTranditions(bodyTempTranditions: TBodyTempTrandition[]): void {
    this.bodyTempTranditions = bodyTempTranditions
  }

  /**
   * ユーザーの日付ごとに代表conditionを算出したデータ
   *
   * @returns {TBodyTempTrandition[]}
   */
  public get getSummarizedTranditions() {
    return this.bodyTempTranditions.map((trandition) => {
      const conditions = _.chain(trandition.conditions)
        .groupBy(({ date }) => date.format('YYYYMMDD'))
        .map((conditions: TCondition[]) => {
          return _.chain(conditions).sortBy(['body_temp']).last().value()
        })
        .value()

      return _.set(trandition, 'conditions', conditions)
    })
  }

  /** ユーザーの体温推移を取得 */
  public get getUserBodyTempTrandition() {
    const userBodyTemp = _.head(this.bodyTempTranditions)

    if (!userBodyTemp) return null

    return {
      history: userBodyTemp.conditions.map((condition) => ({
        dateTime: condition.date,
        bodyTemp: condition.body_temp,
        withSymptoms: _.filter(condition.symptoms, 'onset').length > 0,
        message: condition.message,
        medicalInterviewId: condition.medical_interview?.id || '',
        withContactNotification: condition.with_contact_notification,
      })),
      averageTemperature: _.meanBy(userBodyTemp.conditions, 'body_temp'),
    }
  }

  @Action({ commit: 'setBodyTempTranditions' })
  async fetch(data: {
    startDate: Dayjs
    endDate: Dayjs
    options?: {
      userId?: string
      groupId?: string
    }
  }): Promise<TBodyTempTrandition[]> {
    const response = await axios.get(
      `/body-temp-trandition/${data.startDate.format('YYYYMMDD')}/${data.endDate.format('YYYYMMDD')}`,
      {
        params: _.omitBy(
          {
            group_id: data.options?.groupId,
            user_id: data.options?.userId,
          },
          _.isUndefined
        ),
      }
    )

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return response.data.map((bodyTempTrandition: any): TBodyTempTrandition => {
      return {
        id: bodyTempTrandition.id,
        firstName: bodyTempTrandition.firstName,
        lastName: bodyTempTrandition.lastName,
        conditions: bodyTempTrandition.conditions.map(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (condition: any): TCondition => ({
            body_temp: parseFloat(condition.body_temp),
            with_contact_notification: condition.with_contact_notification,
            message: condition.message,
            date: dayjs(condition.date),
            symptoms: condition.symptoms,
            medical_interview: condition.medical_interview,
          })
        ),
      }
    })
  }
}

export default getModule(Mod)
