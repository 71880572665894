










import { Component, Vue } from 'vue-property-decorator'
import Chart from '@/components/atoms/Chart.vue'
import VABox from 'va/widgets/VABox.vue'
import dayjs from '@/libs/dayjs'
import { ChartConfiguration } from 'chart.js'
import symptomDistributionsStore from '@/store/SymptomDistributions'

/** チャートコンフィグ用のデータセットの型 */
type TSymptomDistributionsDataSet = {
  withSymptomsList: number[]
  noSymptomsList: number[]
  unregisteredList: number[]
  dateList: string[]
}

/** 各グラフの色 */
const colors = {
  withSymptoms: '#f2903c',
  noSymptoms: '#7cafdd',
  unregistered: '#EEEEEE',
}

/** 棒グラフの最大幅 */
const maxBarThickness = 30

@Component({ components: { Chart, VABox } })
export default class extends Vue {
  get chartConfig(): ChartConfiguration {
    const symptomDistributionsDataSet =
      symptomDistributionsStore.symptomDistributions.reduce<TSymptomDistributionsDataSet>(
        (pv, v) => ({
          dateList: [...pv.dateList, dayjs(v.date).format('M/D')],
          withSymptomsList: [...pv.withSymptomsList, v.withSymptom],
          noSymptomsList: [...pv.noSymptomsList, v.noSymptom],
          unregisteredList: [...pv.unregisteredList, v.unregistered],
        }),
        {
          dateList: [],
          withSymptomsList: [],
          noSymptomsList: [],
          unregisteredList: [],
        }
      )

    return {
      type: 'bar',
      data: {
        datasets: [
          {
            label: '有症状者',
            data: symptomDistributionsDataSet.withSymptomsList,
            backgroundColor: colors.withSymptoms,
            maxBarThickness,
          },
          {
            label: '症状なし',
            data: symptomDistributionsDataSet.noSymptomsList,
            backgroundColor: colors.noSymptoms,
            maxBarThickness,
          },
          {
            label: '未登録',
            data: symptomDistributionsDataSet.unregisteredList,
            backgroundColor: colors.unregistered,
            maxBarThickness,
          },
        ],
      },
      options: {
        scales: {
          yAxes: [
            {
              stacked: true,
              ticks: {
                beginAtZero: true,
                callback: (label) => {
                  if (Math.floor(Number(label)) === label) {
                    return label
                  }
                },
              },
            },
          ],
          xAxes: [
            {
              stacked: true,
              type: 'category',
              labels: symptomDistributionsDataSet.dateList,
            },
          ],
        },
        maintainAspectRatio: false,
      },
    }
  }
}
