




import { Component, Vue } from 'vue-property-decorator'
import dayjs from '@/libs/dayjs'

@Component
export default class extends Vue {
  get currentDate(): string {
    return dayjs().format('YYYY年M月D日(ddd)')
  }
}
