import store from '@/store'
import { Module, VuexModule, Action, Mutation, getModule } from 'vuex-module-decorators'
import axios from 'axios'
import config from '@/config'
import { Dayjs } from '@/libs/dayjs'

/** 日毎の症状者数分布の型 */
type TSymptomDistribution = {
  withSymptom: number
  noSymptom: number
  unregistered: number
  date: string
}

@Module({ dynamic: true, namespaced: true, name: 'symptomDistributions', store })
class Mod extends VuexModule {
  symptomDistributions: TSymptomDistribution[] = []

  @Mutation
  setSymptomDistributions(symptomDistributions: TSymptomDistribution[]): void {
    this.symptomDistributions = symptomDistributions
  }

  @Action({ commit: 'setSymptomDistributions' })
  async fetch(data: { startDate: Dayjs; endDate: Dayjs }): Promise<TSymptomDistribution[]> {
    const url =
      `${config.enterprise.apiUrl}/symptom-distributions` +
      `/${data.startDate.format('YYYYMMDD')}` +
      `/${data.endDate.format('YYYYMMDD')}`
    const res = await axios.get(url)

    if (res.status !== 200) {
      throw 'API response error status:' + res.status
    }

    return res.data
  }

  @Action({ commit: 'setSymptomDistributions' })
  async fetchByGroup(data: { startDate: Dayjs; endDate: Dayjs; groupId: string }): Promise<TSymptomDistribution[]> {
    const url =
      `${config.enterprise.apiUrl}/symptom-distributions/group` +
      `/${data.startDate.format('YYYYMMDD')}` +
      `/${data.endDate.format('YYYYMMDD')}`
    const res = await axios.get(url, {
      params: { groupId: data.groupId },
    })

    if (res.status !== 200) {
      throw 'API response error status:' + res.status
    }

    return res.data
  }
}

export default getModule(Mod)
