import store from '@/store'
import { Module, VuexModule, Action, Mutation, getModule } from 'vuex-module-decorators'
import { Dayjs } from '@/libs/dayjs'
import { TBodyTempDistribution } from '@/store/BodyTempDistributions'
import axios from 'axios'
import config from '@/config'

/** グループ体温分布の型 */
export type TGroupBodyTempDistribution = {
  id: string
  name: string
  order: number
  distributions: TBodyTempDistribution[]
}

@Module({ dynamic: true, namespaced: true, name: 'groupBodyTempDistribution', store })
class Mod extends VuexModule {
  groupBodyTempDistributions: TGroupBodyTempDistribution[] = []

  get sortedGroupBodyTempDistributions(): TGroupBodyTempDistribution[] {
    return this.groupBodyTempDistributions.sort((a, b) => {
      return (a.order ?? 0) - (b.order ?? 0)
    })
  }

  @Mutation
  setGroupBodyTempDistributions(groupBodyTempDistributions: TGroupBodyTempDistribution[]): void {
    this.groupBodyTempDistributions = groupBodyTempDistributions
  }

  /** グループの体温分布のデータを読み込む */
  @Action({ commit: 'setGroupBodyTempDistributions' })
  async fetch(data: { date: Dayjs; groupId?: string }): Promise<TGroupBodyTempDistribution[]> {
    const url = `${config.enterprise.apiUrl}/group-body-temp-distribution/${data.date.format('YYYYMMDD')}`
    const res = await axios.get(url, {
      params: { group_id: data.groupId },
    })
    if (res.status !== 200) {
      throw 'API response error status:' + res.status
    }

    return res.data.map(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (v: any): TGroupBodyTempDistribution => ({
        id: v.id,
        name: v.name,
        order: v.order,
        distributions: v.distributions.map(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (distribution: any): TBodyTempDistribution => ({
            date: distribution.date,
            fever: distribution.fever,
            lowGradeFever: distribution.low_grade_fever,
            normal: distribution.normal,
            unregistered: distribution.unregistered,
          })
        ),
      })
    )
  }
}

export default getModule(Mod)
