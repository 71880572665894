










import { Component, Vue } from 'vue-property-decorator'
import dayjs, { Dayjs } from '@/libs/dayjs'
import 'chartjs-plugin-colorschemes'
import Chart from '@/components/atoms/Chart.vue'
import VABox from 'va/widgets/VABox.vue'
import { ChartConfiguration } from 'chart.js'
import bodyTempTranditionsStore, { TBodyTempTrandition, TCondition } from '@/store/BodyTempTranditions'

@Component({
  components: { Chart, VABox },
})
export default class extends Vue {
  get chartConfig(): ChartConfiguration {
    const startDate: Dayjs = dayjs().subtract(1, 'months').add(1, 'days')
    const endDate: Dayjs = dayjs()
    const displayedDates: Dayjs[] = [...Array(endDate.diff(startDate, 'days') + 1).keys()].map((day) => {
      return startDate.clone().add(day, 'days')
    })

    const dataSet = bodyTempTranditionsStore.getSummarizedTranditions.map((trandition: TBodyTempTrandition) => {
      return {
        label: `${trandition.lastName} ${trandition.firstName}`,
        backgroundColor: 'rgba(0, 0, 0, 0.0)',
        data: displayedDates.map((date: Dayjs): number | null => {
          const condition = trandition.conditions.find((condition: TCondition) => {
            return condition.date.isSame(date, 'days')
          })

          return condition ? condition.body_temp : null
        }),
      }
    })

    return {
      type: 'line',
      data: {
        datasets: dataSet,
      },
      options: {
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              type: 'category',
              labels: displayedDates.map((date: Dayjs) => date.format('YYYY-MM-DD')),
            },
          ],
          yAxes: [
            {
              ticks: {
                callback: function (label: string): number {
                  return Math.floor(parseFloat(label) * 10) / 10
                },
              },
            },
          ],
        },
        // ベジェ曲線無効
        elements: { line: { tension: 0 } },
      },
    }
  }
}
