












































import { Component, Vue } from 'vue-property-decorator'
import conditionsStore, { ErrorMsg } from '@/store/Conditions'
import Datepicker from 'vuejs-datepicker'
import Spinner from '@/components/atoms/Spinner.vue'
import Modal from '@/components/molecules/Modal.vue'
import { ja } from 'vuejs-datepicker/dist/locale'
import dayjs from '@/libs/dayjs'
import Csv from '@/libs/Csv'
import { TSymptom } from '@/store/Conditions'

@Component({
  components: { Spinner, Datepicker, Modal },
})
export default class extends Vue {
  $fixApp!: (fixed: boolean) => void
  isOpen = false
  modalClose(): void {
    this.isOpen = false
    this.$fixApp(false)
  }
  modalOpen(): void {
    this.isOpen = true
    this.$fixApp(true)
  }

  isLoading = false
  ja = ja
  fromDate = dayjs().subtract(1, 'M').add(1, 'd').format('YYYY/MM/DD')
  toDate = dayjs().format('YYYY/MM/DD')
  error = ''

  datepickerFormatter(date: string): string {
    return dayjs(date).format('YYYY/MM/DD')
  }

  datepickerSelectionLimit = { to: dayjs().subtract(1, 'y').toDate(), from: dayjs().toDate() }

  async downloadCSV(): Promise<void> {
    const startDate = dayjs(this.fromDate)
    const endDate = dayjs(this.toDate)
    const groupId = this.$route.params.id

    this.error = ''

    this.isLoading = true
    try {
      await conditionsStore.fetchByGroupIdAndRange({ startDate, endDate, groupId })
    } catch (e) {
      switch (e) {
        case ErrorMsg.responseError(460):
          this.error = ErrorMsg.OverMaxCountCsvError
          return
        case ErrorMsg.responseError(502):
          this.error = ErrorMsg.BusyCsvDownloadProcessError
          return
        default:
          this.error = ErrorMsg.FailedToDownloadCsv
          return
      }
    } finally {
      this.isLoading = false
    }

    const csvHeader = ['ID', '氏名', '日付・時刻', '体温(体調情報)', '症状(体調情報)', 'メッセージ']
    const csvData = conditionsStore.groupCsvData.map((condition, i) => {
      return [
        i + 1,
        condition.user.lastName + ' ' + condition.user.firstName,
        condition.date.format('YYYY/MM/DD HH:mm:ss'),
        condition.bodyTemp.toFixed(1),
        this.getSymptoms(condition.symptoms),
        condition.message,
      ]
    })

    const csv = Csv.create(csvHeader, csvData)

    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(new Blob([csv], { type: 'text/csv' }))
    link.download = 'Conditions.csv'
    link.click()
  }

  /**
   * 症状取得
   *
   * 全種類の症状の中から発症しているものだけを抽出して「/」区切りで表示
   */
  private getSymptoms(symptoms?: TSymptom[]): string {
    return (
      symptoms
        ?.filter((v) => v.onset)
        .map((v) => v.name)
        .join('/') || ''
    )
  }
}
