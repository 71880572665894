










import { Component, Vue } from 'vue-property-decorator'
import Chart from '@/components/atoms/Chart.vue'
import VABox from 'va/widgets/VABox.vue'
import { ChartConfiguration } from 'chart.js'
import { last } from 'lodash'
import groupBodyTempDistributionsStore from '@/store/GroupBodyTempDistributions'

@Component({
  components: { Chart, VABox },
})
export default class extends Vue {
  get total(): number | string {
    const groupBodyTempDistribution = last(groupBodyTempDistributionsStore.groupBodyTempDistributions)
    const latestGroupBodyTempDistribution = last(groupBodyTempDistribution?.distributions)
    if (latestGroupBodyTempDistribution) {
      const { fever, lowGradeFever, normal, unregistered } = latestGroupBodyTempDistribution
      return fever + lowGradeFever + normal + unregistered
    }
    return ''
  }
  get chartConfig(): ChartConfiguration {
    const groupBodyTempDistribution = last(groupBodyTempDistributionsStore.groupBodyTempDistributions)
    const latestGroupBodyTempDistribution = last(groupBodyTempDistribution?.distributions)
    const bodyTempList = latestGroupBodyTempDistribution
      ? [
          latestGroupBodyTempDistribution.fever,
          latestGroupBodyTempDistribution.lowGradeFever,
          latestGroupBodyTempDistribution.normal,
          latestGroupBodyTempDistribution.unregistered,
        ]
      : []

    return {
      type: 'doughnut',
      data: {
        datasets: [
          {
            backgroundColor: [colors.fever, colors.low_grade_fever, colors.normal, colors.unregistered],
            data: bodyTempList,
          },
        ],
        labels: ['発熱', '微熱', '正常', '未登録'],
      },
      options: { plugins: { center: { text: this.total } } },
    }
  }
}

// 各グラフの色
const colors = {
  fever: '#ff4800',
  low_grade_fever: '#ffd000',
  normal: '#00aeff',
  unregistered: '#EEEEEE',
}
